<template>
    <div>
        <el-form :model="form" :rules="formRules" ref="formRef" label-position="">
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="影视名称" prop="name">
                        <el-input v-model="form.name" placeholder="请输入名称"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="影视的URL">
                        <el-input v-model="form.url" placeholder="TV的URL地址"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-dialog :visible.sync="imgDialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>

            <!--            <el-row :gutter="20">-->
            <!--                <el-col :span="12">-->
            <el-form-item label="影视分类" prop="cate">
                <el-select v-model="form.cate" placeholder="请选择分类"
                           :filterable="true" style="width: 100%;">
                    <el-option :label="item.name" :value="item.id" v-for="item in categoryList"
                               :key="item.id"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="影视标签" prop="typeList">
                <el-select v-model="form.typeList" placeholder="请选择标签"
                           :filterable="true" :multiple="true" style="width: 100%;">
                    <el-option :label="item.type_name" :value="item.id" v-for="item in typeList"
                               :key="item.id"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="缩略图" required class="upload">
                <el-upload
                        action="http://127.0.0.1:3000/ab"
                        :show-file-list="false"
                        class="middle_list"
                        list-type="picture-card"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload"
                        ref="upload">
                    <img v-if="form.img" :src="form.img" class="avatar" alt="">
                    <i v-else class="el-icon-plus"></i>
                </el-upload>
            </el-form-item>

            <el-form-item label="观看状态">
                <el-radio-group v-model="form.watch">
                    <el-radio :label="0">正在看</el-radio>
                    <el-radio :label="1">还未看</el-radio>
                    <el-radio :label="2">已看完</el-radio>
                </el-radio-group>
            </el-form-item>

            <el-form-item style="text-align: right;">
                <el-button type="primary" @click="dialogVisible=true">保存</el-button>
            </el-form-item>
        </el-form>

        <!--编辑可见性状态对话框-->
        <el-dialog title="确认操作" width="30%" :visible.sync="dialogVisible">
            <!--底部-->
            <span slot="footer">
				<el-button @click="dialogVisible=false">取 消</el-button>
				<el-button type="primary" @click="submit">保存</el-button>
			</span>
        </el-dialog>
    </div>
</template>

<script>
    import {getCategoryAndType, addTV, updateTV, getTVById} from '@/api/television'

    export default {
        name: "Uploads",
        data() {
            return {
                files: [],
                categoryList: [],
                typeList: [],
                dialogImageUrl: '',
                imgDialogVisible: false,
                dialogVisible: false,
                form: {
                    name: '',
                    img: '',
                    url: '',
                    size: '',
                    // "TV分类"
                    cate: '',
                    // "TV类型"
                    typeList: '',
                    watch: 0,
                },
                formRules: {
                    name: [{required: true, message: '请输入名称', trigger: 'change'}],
                    cate: [{required: true, message: '请选择分类', trigger: 'change'}],
                    typeList: [{required: true, message: '请选择标签', trigger: 'change'}],
                },
            }
        },
        created() {
            this.getData()
            if (this.$route.params.id) {
                this.getTelevision(this.$route.params.id)
            }
        },
        methods: {
            handleAvatarSuccess(res, file) {
                console.log(file)
                fetch(file.url).then(data => {
                    const blob = data.blob();
                    return blob;
                }).then(blob => {
                    let reader = new window.FileReader();
                    // 使用bind改变指针
                    reader.onloadend = function () {
                        this.form.img = reader.result;
                        // file.watch = this.conditions.watch
                        this.setData(file)
                    }.bind(this);
                    reader.readAsDataURL(blob);
                });
            },
            setData(file) {
                if (!this.form.name) {
                    this.form.name = file.name
                }
                this.form.size = file.size
            },
            beforeAvatarUpload(file) {
                const isIMG = file.type === 'image/jpeg';
                const isLt5M = file.size / 1024 < 100;

                if (!isIMG) {
                    this.$message.error('上传图片只能是JPG格式!');
                }
                if (!isLt5M) {
                    this.$message.error('上传图片大小不能超过100kb');
                }
                return isIMG && isLt5M;
            },
            getData() {
                getCategoryAndType().then(res => {
                    this.categoryList = res.data.cate
                    this.typeList = res.data.types
                })
            },
            getTelevision(id) {
                getTVById(id).then(res => {
                    console.log(res.data[0])
                    this.computeCategoryAndType(res.data[0])
                    this.form = res.data[0]
                })
            },
            computeCategoryAndType(TV) {
                TV.typeList = []
                TV.types.forEach(item => {
                    TV.typeList.push(item)
                })

            },
            submit() {
                this.$refs.formRef.validate(valid => {
                    if (valid) {
                        if (this.$route.params.id) {
                            updateTV(this.form).then(res => {
                                this.msgSuccess(res.message)
                                this.$router.push('/page/tv/list')
                            })
                        } else {
                            if (!this.form.img) return this.msgError('请上传图片')
                            addTV(this.form).then(res => {
                                this.msgSuccess(res.message)
                                this.$router.push('/page/tv/list')
                            })
                        }
                    } else {
                        this.dialogVisible = false
                        return this.msgError('请填写必要的表单项')
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .avatar {
        width: 146px;
        height: 146px;
    }

</style>
<style>
    .el-upload {
        margin-top: 14px !important;
    }
</style>